import React, { useRef, useContext, useEffect } from 'react'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css'
import { Button } from '@mui/material'
import { MainContext } from '../contexts/MainContext'
import { useNavigate } from 'react-router-dom'


function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const CropImage = () => {
  const { crop, setCrop, userImageSrc, setUserImage, localization } = useContext(MainContext);
  const imgRef = useRef<HTMLImageElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!userImageSrc) navigate('/')
  }, [userImageSrc, navigate])

  const nextStep = () => {
    navigate('/result')
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, 1))
  }


  return (
    <div className="App" style={{ width: '100%' }}>
      {
        userImageSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => {
              setCrop(percentCrop)
            }}
            aspect={1}
            minHeight={150}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={userImageSrc}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
      <Button
        onClick={() => {
          if (imgRef.current) {
            setUserImage(imgRef.current)
            nextStep();
          }
        }}
        variant='contained'
        fullWidth
      >
        {localization.text.buttonCrop}
      </Button>
    </div>
  )
}

export default CropImage