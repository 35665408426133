/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Modal, Paper, Typography } from '@mui/material'
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../contexts/MainContext';

const Home = () => {
  const [externalBrowserModalOpen, setExternalBrowserModalOpen] = useState<boolean>(false)
  const browserInstagram = navigator.userAgent.match(/Instagram/i);
  const browserFb = navigator.userAgent.match(/FBAN|FBAV/i);
  const browserIos = navigator.userAgent.match(/iPad|iPhone|iPod/i);
  const unsupportedBrowser = (browserFb || browserInstagram) !== null;
  const { localization } = useContext(MainContext)

  return (
    <div>
      <Modal
        open={externalBrowserModalOpen}
        onClose={() => setExternalBrowserModalOpen(false)}
      >
        <>
          {
            !browserIos &&
            <img src='arrow.png' style={{
              position: 'absolute',
              top: '12px',
              right: '12px',
              width: '2rem',
            }} alt='arrow' />
          }
          <Paper elevation={2} sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '75%',
            p: 4,
          }}>
            <Typography>
              {
                localization.text.browserNotSupported
              }
            </Typography>
            <br />
            {
              browserFb &&
              <>
                <img src='modal-fb.png' alt='drowserfb' width={'100%'} />
              </>
            }
            {
              browserInstagram &&
              <img src='modal-instagram.png' alt='externalb' width={'100%'} />
            }
            <br />
            <Typography variant='caption'>
              {
                localization.text.browserDisclaimer
              }
            </Typography>
          </Paper>
        </>
      </Modal>
      <img src={'explanation.png'} alt='explanation' style={{ paddingRight: '1rem', paddingLeft: '1rem' }} />
      {
        unsupportedBrowser
          ? <a href="#" download target="_blank" onClick={() => setExternalBrowserModalOpen(true)}>
            <StartButton unsupportedBrowser />
          </a>
          : <StartButton />
      }

    </div>
  )
}

const StartButton = (props: { unsupportedBrowser?: boolean }) => {
  const { setUserImageSrc, localization } = useContext(MainContext);

  const navigate = useNavigate();

  const nextStep = () => {
    navigate('/crop')
  }

  return (
    <>
      <Button
        fullWidth
        color='primary'
        variant="contained"
        component="label"
        sx={{ mt: 3, mb: 2 }}
      >
        {localization.text.buttonStart}
        {!props.unsupportedBrowser &&
          < input
            type="file"
            onChange={(event) => {
              if (event.target.files !== null && event.target.files !== null) {
                const src = URL.createObjectURL(event.target.files[0]);
                setUserImageSrc(src);
                nextStep();
              }
            }}
            hidden
          />
        }
      </Button>
    </>
  )
}

export default Home