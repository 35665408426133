import { useContext, useEffect, useState } from 'react';
import { MainContext } from '../contexts/MainContext';
import { hostages } from '../hostages';
import { Box, Button, Divider, Grid, IconButton, MenuItem, Paper, Select, Snackbar, Typography, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DOMAIN } from '../App';
import { Link, useNavigate } from 'react-router-dom';

const SIZE_MULTIPLIER = 1.5;
const GLOBAL_ANGLE_OFFSET = -0.29;
const LOWER_TEXT_ANGLE_OFFSET = 0.06;
const HOSTAGE_IMAGE_GAP = 0.405;
const HOSTAGE_IMAGE_SIZE = 150 * SIZE_MULTIPLIER;
const HOSTAGE_ANGLE_OFFSET = 0.352;
const FONT = 'Menlo';
const CIRCLE_EDGE_GAP = 0.96;
const FONT_SIZE_BOTTOM = 53 * SIZE_MULTIPLIER;
const CIRCLE_WIDTH = FONT_SIZE_BOTTOM * 1.56;
const NATIONAL_CHILDRENS_DAY = new Date(2023, 10, 20)

const ImageOverlay = () => {
  const { crop, mergedImageSrc, hebPhraseImage, setMergedImageSrc, randomizeHostage, restart, setHostage, userImage, hostageData, localization, changeFrame, frame } = useContext(MainContext);
  const { name: hostageName, age: hostageAge, imageSrc: hostageImageSrc } = hostageData;
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const theme = useTheme();

  const navigate = useNavigate();

  const nextStep = () => {
    navigate('/');
  }

  useEffect(() => {
    if (!crop) navigate('/')
  }, [crop, navigate])

  const Oct7 = new Date(2023, 9, 7);
  const thisDate = new Date();
  const daysPassed = Math.round((thisDate.getTime() - Oct7.getTime()) / (1000 * 60 * 60 * 24));

  // const contentSuggestionsEnglish = []
  // const contentSuggestionsHebrew = []

  // if (thisDate.getMonth() === NATIONAL_CHILDRENS_DAY.getMonth()
  //   && thisDate.getDate() === NATIONAL_CHILDRENS_DAY.getDate()
  //   && Number(hostageAge)
  //   && (Number(hostageAge) <= 16 || hostageName === 'Kfir Bibas')) {
  //   contentSuggestionsHebrew.push(`יום הילד הבינלאומי, והילדים שלנו עדיין מוחזקים בעזה. #ForEveryChild #WorldChildrensDay #BringThemHomeNow`);
  //   contentSuggestionsEnglish.push(`Today is World Children's Day, and ${hostageName}, ${hostageAge}, is still being held by Hamas. #ForEveryChild #WorldChildrensDay #BringThemHomeNow`)
  // }

  // contentSuggestionsEnglish.push(
  //   `${hostageName} was kidnapped by Hamas terrorists, together with over 230 men, women and children. Add a frame to your photo and join our call to #BringThemHomeNow`,
  //   `${hostageName.toUpperCase()}${hostageAge && ', ' + hostageAge}, KIDNAPPED BY HAMAS.`,
  //   `No one will take you down from our walls. 🤍`,
  // );


  // contentSuggestionsHebrew.push(
  //   `מסגרת למען השבת החטופים:`,
  //   `את כולם ✊🏼`,
  // );

  // if (!hostageData.notOct7) {
  //   contentSuggestionsHebrew.push(`${daysPassed} ימים בשבי החמאס. מחזירים אותם הביתה עכשיו.`);
  //   contentSuggestionsEnglish.push(`Day ${daysPassed} since ${hostageName} was kidnapped to Gaza. Stand with us and create your frame at:`)
  // }


  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');



  const generateImage2 = () => {

    const hostageImage = new Image();

    hostageImage.onload = () => {
      if (!crop) return;
      setTimeout(() => {

        // Calculate canvas dimensions while maintaining aspect ratio
        const canvasSize = 720 * SIZE_MULTIPLIER;

        canvas.width = canvasSize;
        canvas.height = canvasSize;

        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        ctx!.drawImage(
          userImage!,
          crop!.x * userImage!.width / 100,
          crop!.y * userImage!.height / 100,
          crop!.width * userImage!.width / 100,
          crop!.height * userImage!.height / 100,
          0,
          0,
          canvas.width,
          canvas.height);

        {
          // Add circular path text watermark anchored to the bottom and centered
          const radius = canvasSize / 2 * CIRCLE_EDGE_GAP;

          ctx!.font = `900 ${FONT_SIZE_BOTTOM}pt ${FONT}, sans-serif`;
          ctx!.textAlign = 'center';

          ctx!.save();
          ctx!.beginPath();
          ctx!.arc(
            centerX,
            centerY,
            radius - FONT_SIZE_BOTTOM / 2,
            0,
            Math.PI * 2,
            true
          )
          ctx!.lineWidth = CIRCLE_WIDTH;
          ctx!.strokeStyle = `${theme.palette.primary.main}`;
          ctx!.stroke();
          ctx!.restore();
        }

        {
          // Add circular path text watermark anchored to the bottom and centered

          const textHostage = `${hostageName.trim()}`.toUpperCase();
          const fontSizeTop = (textHostage.length <= 19 ? 45 : 34) * SIZE_MULTIPLIER;
          const radius = canvasSize / 2 * CIRCLE_EDGE_GAP - (fontSizeTop) - (FONT_SIZE_BOTTOM - fontSizeTop) / 2;
          const anglePerLetter = textHostage.length <= 19 ? Math.PI / 23.5 : (Math.PI - 0.6) / textHostage.length;

          ctx!.font = `normal 900 ${fontSizeTop}pt ${FONT}, sans-serif`;

          for (let i = 0; i < textHostage.length; i++) {
            const angle = (i * anglePerLetter) - anglePerLetter * textHostage.length / 2 + Math.PI / 2 + HOSTAGE_ANGLE_OFFSET;
            const letterX = centerX - radius * Math.cos(angle);
            const letterY = centerY - radius * Math.sin(angle);

            ctx!.save();
            ctx!.translate(letterX, letterY);
            ctx!.rotate(angle - Math.PI / 2);
            ctx!.fillStyle = 'black';
            // ctx!.strokeStyle = 'black';
            ctx!.lineCap = 'round';
            ctx!.lineJoin = 'round';
            // ctx!.lineWidth = 4 * SIZE_MULTIPLIER;
            ctx!.strokeText(textHostage[i], 0, 0);
            ctx!.fillText(textHostage[i], 0, 0);
            ctx!.restore();
          }

          // Crop and draw image3 as a circle with black stroke
          const imageSize = HOSTAGE_IMAGE_SIZE;
          const angle = -anglePerLetter * textHostage.length / 2 + Math.PI / 2 - HOSTAGE_IMAGE_GAP + HOSTAGE_ANGLE_OFFSET;
          const xImage3 = centerX - (radius - (CIRCLE_WIDTH - fontSizeTop) / 2 + CIRCLE_WIDTH - HOSTAGE_IMAGE_SIZE / 2) * Math.cos(angle) - HOSTAGE_IMAGE_SIZE / 2;
          const yImage3 = centerY - (radius - (CIRCLE_WIDTH - fontSizeTop) / 2 + CIRCLE_WIDTH - HOSTAGE_IMAGE_SIZE / 2) * Math.sin(angle) - HOSTAGE_IMAGE_SIZE / 2;

          ctx!.save();
          ctx!.beginPath();
          ctx!.arc(xImage3 + imageSize / 2, yImage3 + imageSize / 2, imageSize / 2, 0, 2 * Math.PI);
          ctx!.clip();
          ctx!.globalAlpha = 1;
          ctx!.drawImage(hostageImage, xImage3, yImage3, imageSize, imageSize);
          ctx!.strokeStyle = `${theme.palette.primary.main}`
          ctx!.lineCap = 'round';
          ctx!.lineJoin = 'round';
          ctx!.lineWidth = 10 * SIZE_MULTIPLIER;
          ctx!.stroke();
          ctx!.restore();

        }

        {
          const fontSize = 20 * SIZE_MULTIPLIER;

          const text1 = DOMAIN;

          ctx!.font = `${fontSize}px arial`;
          ctx!.textAlign = 'left';
          ctx!.save();
          ctx!.translate(4 * SIZE_MULTIPLIER, canvasSize - 5 * SIZE_MULTIPLIER);
          ctx!.fillStyle = 'lightgray';
          ctx!.strokeStyle = 'black';
          ctx!.lineCap = 'round';
          ctx!.lineJoin = 'round';
          ctx!.lineWidth = 3 * SIZE_MULTIPLIER;
          ctx!.strokeText(text1, 0, 0);
          ctx!.fillText(text1, 0, 0);
          ctx!.restore();
        }
        // }

        // hebrew phrase

        ctx!.drawImage(
          hebPhraseImage,
          0,
          0,
          canvas.width,
          canvas.height);

        const mergedImage = canvas.toDataURL('image/png');

        setMergedImageSrc(mergedImage)
      }, 50)
    };

    hostageImage.src = hostageImageSrc ? `/hstg/${hostageImageSrc}` : `david star.png`;

  }


  const generateImage1 = () => {

    const hostageImage = new Image();

    hostageImage.onload = () => {
      if (!crop) return;
      setTimeout(() => {

        // Calculate canvas dimensions while maintaining aspect ratio
        const canvasSize = 720 * SIZE_MULTIPLIER;

        canvas.width = canvasSize;
        canvas.height = canvasSize;

        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        ctx!.drawImage(
          userImage!,
          crop!.x * userImage!.width / 100,
          crop!.y * userImage!.height / 100,
          crop!.width * userImage!.width / 100,
          crop!.height * userImage!.height / 100,
          0,
          0,
          canvas.width,
          canvas.height);

        {
          // Add circular path text watermark anchored to the bottom and centered
          const radius = canvasSize / 2 * CIRCLE_EDGE_GAP;

          const textBring = 'BRING THEM HOME ';
          const textNow = 'NOW';
          const anglePerLetter = (Math.PI) / (textBring.length + textNow.length - 1) * 0.84;

          ctx!.font = `900 ${FONT_SIZE_BOTTOM}pt ${FONT}, sans-serif`;
          ctx!.textAlign = 'center';

          ctx!.save();
          ctx!.beginPath();
          ctx!.arc(
            centerX,
            centerY,
            radius - FONT_SIZE_BOTTOM / 2,
            0,
            Math.PI * 2,
            true
          )
          ctx!.lineWidth = CIRCLE_WIDTH;
          ctx!.strokeStyle = "black";
          ctx!.stroke();
          ctx!.restore();


          for (let i = 0; i < textBring.length; i++) {
            const angle = + GLOBAL_ANGLE_OFFSET + LOWER_TEXT_ANGLE_OFFSET - i * anglePerLetter;
            const letterX = centerX - radius * Math.cos(angle);
            const letterY = centerY - radius * Math.sin(angle);

            ctx!.save();
            ctx!.translate(letterX, letterY);
            ctx!.rotate(angle + Math.PI / 2);
            ctx!.fillStyle = 'white';
            ctx!.strokeStyle = 'black';
            ctx!.lineWidth = 4 * SIZE_MULTIPLIER;
            ctx!.lineCap = 'round';
            ctx!.lineJoin = 'round';
            ctx!.strokeText(textBring[i], 0, 0);
            ctx!.fillText(textBring[i], 0, 0);
            ctx!.restore();
          }

          for (let i = 0; i < textNow.length; i++) {
            const angle = -((i + textBring.length) * anglePerLetter) + GLOBAL_ANGLE_OFFSET + LOWER_TEXT_ANGLE_OFFSET;
            const letterX = centerX - radius * Math.cos(angle);
            const letterY = centerY - radius * Math.sin(angle);

            ctx!.save();
            ctx!.translate(letterX, letterY);
            ctx!.rotate(angle + Math.PI / 2);
            ctx!.fillStyle = '#e92802';
            ctx!.strokeStyle = 'black';
            ctx!.lineWidth = 4 * SIZE_MULTIPLIER;
            ctx!.lineCap = 'round';
            ctx!.lineJoin = 'round';
            ctx!.strokeText(textNow[i], 0, 0);
            ctx!.fillText(textNow[i], 0, 0);
            ctx!.restore();
          }
        }

        {
          // Add circular path text watermark anchored to the bottom and centered

          const textHostage = `${hostageName.trim()}`.toUpperCase();
          const fontSizeTop = (textHostage.length <= 19 ? 45 : 34) * SIZE_MULTIPLIER;
          const radius = canvasSize / 2 * CIRCLE_EDGE_GAP - (fontSizeTop) - (FONT_SIZE_BOTTOM - fontSizeTop) / 2;
          const anglePerLetter = textHostage.length <= 19 ? Math.PI / 23.5 : (Math.PI - 0.6) / textHostage.length;

          ctx!.font = `normal 900 ${fontSizeTop}pt ${FONT}, sans-serif`;

          for (let i = 0; i < textHostage.length; i++) {
            const angle = (i * anglePerLetter) - anglePerLetter * textHostage.length / 2 + Math.PI / 2 + HOSTAGE_ANGLE_OFFSET;
            const letterX = centerX - radius * Math.cos(angle);
            const letterY = centerY - radius * Math.sin(angle);

            ctx!.save();
            ctx!.translate(letterX, letterY);
            ctx!.rotate(angle - Math.PI / 2);
            ctx!.fillStyle = 'white';
            ctx!.strokeStyle = 'black';
            ctx!.lineCap = 'round';
            ctx!.lineJoin = 'round';
            ctx!.lineWidth = 4 * SIZE_MULTIPLIER;
            ctx!.strokeText(textHostage[i], 0, 0);
            ctx!.fillText(textHostage[i], 0, 0);
            ctx!.restore();
          }

          // Crop and draw image3 as a circle with black stroke
          const imageSize = HOSTAGE_IMAGE_SIZE;
          const angle = -anglePerLetter * textHostage.length / 2 + Math.PI / 2 - HOSTAGE_IMAGE_GAP + HOSTAGE_ANGLE_OFFSET;
          const xImage3 = centerX - (radius - (CIRCLE_WIDTH - fontSizeTop) / 2 + CIRCLE_WIDTH - HOSTAGE_IMAGE_SIZE / 2) * Math.cos(angle) - HOSTAGE_IMAGE_SIZE / 2;
          const yImage3 = centerY - (radius - (CIRCLE_WIDTH - fontSizeTop) / 2 + CIRCLE_WIDTH - HOSTAGE_IMAGE_SIZE / 2) * Math.sin(angle) - HOSTAGE_IMAGE_SIZE / 2;

          ctx!.save();
          ctx!.beginPath();
          ctx!.arc(xImage3 + imageSize / 2, yImage3 + imageSize / 2, imageSize / 2, 0, 2 * Math.PI);
          ctx!.clip();
          ctx!.globalAlpha = 1;
          ctx!.drawImage(hostageImage, xImage3, yImage3, imageSize, imageSize);
          ctx!.strokeStyle = 'black';
          ctx!.lineCap = 'round';
          ctx!.lineJoin = 'round';
          ctx!.lineWidth = 10 * SIZE_MULTIPLIER;
          ctx!.stroke();
          ctx!.restore();

        }

        {
          const fontSize = 20 * SIZE_MULTIPLIER;

          const text1 = DOMAIN;

          ctx!.font = `${fontSize}px arial`;
          ctx!.textAlign = 'left';
          ctx!.save();
          ctx!.translate(4 * SIZE_MULTIPLIER, canvasSize - 5 * SIZE_MULTIPLIER);
          ctx!.fillStyle = 'lightgray';
          ctx!.strokeStyle = 'black';
          ctx!.lineCap = 'round';
          ctx!.lineJoin = 'round';
          ctx!.lineWidth = 3 * SIZE_MULTIPLIER;
          ctx!.strokeText(text1, 0, 0);
          ctx!.fillText(text1, 0, 0);
          ctx!.restore();
        }

        const mergedImage = canvas.toDataURL('image/png');

        setMergedImageSrc(mergedImage)
      }, 50)
    };

    hostageImage.src = hostageImageSrc ? `/hstg/${hostageImageSrc}` : `david star.png`;
  }

  const frames = [
    generateImage1, generateImage2
  ]

  frames[frame]()

  const copyContent = (postContent: string) => {
    navigator.clipboard.writeText(postContent);
    setSnackBarOpen(true);
  }

  return (
    <div>
      {!mergedImageSrc
        ? <CircularProgress size='4rem' sx={{ marginTop: '6rem', marginBottom: '6rem' }} />
        : <>
          <Grid direction={localization.align === 'right' ? 'row-reverse' : 'row'} container gap={2}>
            <Grid item xs>
              <Button
                fullWidth
                variant='outlined'
                onClick={() => {
                  restart()
                  nextStep()
                }}
              >
                {
                  localization.align === 'right'
                    ? <ArrowForwardIcon />
                    : < ArrowBackIcon />
                }
                {localization.text['buttonRestart']}
              </Button>
            </Grid>
            <Grid item xs >
              <Button
                fullWidth
                variant='outlined'
                onClick={() => {
                  changeFrame()
                }}
              >
                {localization.text.buttonChangeFrame}
              </Button>
            </Grid>
          </Grid>
          <br />
          <img src={mergedImageSrc} alt='mergedImage' />
          <br />
          <br />
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Select
                size='small'
                variant='outlined'
                labelId="select-hostage"
                id="select-hostage"
                value={hostageName}
                onChange={(e) => setHostage(e.target.value)}
                fullWidth
              >
                {
                  hostages.sort((a, b) => a.name.localeCompare(b.name)).map(hostage => (
                    <MenuItem dir='ltr' key={hostage.name} value={hostage.name}>{`${hostage.name.trim()}${hostage.age && ', ' + hostage.age}`}</MenuItem>
                  ))
                }
              </Select>
            </Grid>
            <Grid item xs>

              <Button
                fullWidth
                variant='outlined'
                onClick={randomizeHostage}
              >
                <ReplayCircleFilledIcon />
                {localization.text.buttonRandom}
              </Button>
            </Grid>
            <Grid item xs>
              <Link
                to={mergedImageSrc}
                download="Framed Image"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  fullWidth
                  variant='contained'
                >
                  <DownloadIcon />
                  {localization.text.buttomDownload}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Typography align={localization.align}>
                {localization.text.suggestedContent}
              </Typography>
              {
                [
                  localization.text.postContent1,
                  localization.text.postContent2,
                  localization.text.postContent3,
                  localization.text.postContent4,
                ].filter(s => s !== undefined)
                  .map(suggestion =>
                    <ContentSuggestion rtl={localization.dir === 'rtl'} content={suggestion!} copyContent={copyContent} key={suggestion} />
                  )
              }

            </Grid>

          </Grid>
          <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={snackBarOpen} autoHideDuration={4000} onClose={() => setSnackBarOpen(false)}>
            <Paper sx={{ display: 'flex', columnGap: 1, p: 1, borderColor: theme.palette.primary.dark, backgroundColor: theme.palette.grey[900] }} variant='outlined'>
              <CheckCircleOutlineIcon color='primary' />
              <Typography variant='body1' color={theme.palette.primary.main}>
                {
                  localization.text.contentCopied
                }
              </Typography>
            </Paper>
          </Snackbar>
        </>
      }
    </div >
  );
};

const ContentSuggestion = (props: { content: string, copyContent: (text: string) => void, rtl?: boolean }) => {

  const FULL_SITE_URL = `https://${DOMAIN}`;

  const enrichedContent = `${props.content}\n${FULL_SITE_URL}`
  return (
    <div dir={props.rtl ? 'rtl' : 'ltr'}>
      <Paper elevation={2} sx={{ display: 'flex', mt: 1 }} >
        <Box sx={{ pt: 1 }}>
          <IconButton size='small' onClick={() => props.copyContent(enrichedContent)} >
            <ContentCopyIcon color='primary' />
          </IconButton>
        </Box>
        <Divider flexItem orientation='vertical' />
        <div onClick={() => props.copyContent(enrichedContent)}>
          <Box sx={{ p: 1 }}>
            <Typography align={props.rtl ? 'right' : 'left'} variant='body2'>
              {props.content}
            </Typography>
            <Typography align={props.rtl ? 'right' : 'left'} variant='body2'>
              {FULL_SITE_URL}
            </Typography>
          </Box>
        </div>
      </Paper>
    </div>
  )
}

export default ImageOverlay;