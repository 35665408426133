export type IHostageData = { name: string, age: string, imageSrc: string, notOct7?: boolean }

export const hostages: IHostageData[] = [
  {
    "name": "Tamir Adar",
    "age": "38",
    "imageSrc": "6547d1bf67ae354bf92c37e5_Tamir Adar.jpg"
  },
  {
    "name": "Tomer Ahimas",
    "age": "20",
    "imageSrc": ""
  },
  {
    "name": "Hisham Shaaban Al-Sayed",
    "age": "35",
    "imageSrc": "6548dd27d84f3bb5447c243e_Hisham Shaaban A Said.jpg"
  },
  {
    "name": "Liri Albag",
    "age": "18",
    "imageSrc": "65467772df5823449b650a43_LIRI ALBAG.jpg"
  },
  {
    "name": "Edan Alexander",
    "age": "19",
    "imageSrc": "65467772df5823449b650b09_Template.jpg"
  },
  {
    "name": "Matan Angrest",
    "age": "21",
    "imageSrc": "65467772df5823449b650a46_Matan angrest, 21.jpg"
  },
  {
    "name": "Noa Argamani",
    "age": "26",
    "imageSrc": "65467772df5823449b650ab2_Argamani Noa.jpg"
  },
  {
    "name": "Karina Ariev",
    "age": "19",
    "imageSrc": "65467772df5823449b650693_Karina Ariev.jpg"
  },
  {
    "name": "Aviv Atzili",
    "age": "49",
    "imageSrc": "65467772df5823449b650974_Atzili Aviv.jpg"
  },
  {
    "name": "Noralin Babadilla Agojo",
    "age": "60",
    "imageSrc": "654ce02de168f48e7b7188b0_Babadilla Agojo  Noralin.jpg"
  },
  {
    "name": "Sahar Baruch",
    "age": "24",
    "imageSrc": "655239b46833203602cbea93_Baruch Sahar.jpg"
  },
  {
    "name": "Uriel Baruch",
    "age": "35",
    "imageSrc": "65467772df5823449b650ad7_Baruch Uriel.jpg"
  },
  {
    "name": "Nik Beizer",
    "age": "19",
    "imageSrc": "65467772df5823449b650a6d_Nik beizer.jpg"
  },
  {
    "name": "Ohad Ben Ami  ",
    "age": "55",
    "imageSrc": "65467772df5823449b650b1d_OHAD BEN AMI.jpg"
  },
  {
    "name": "Ron Benjamin",
    "age": "53",
    "imageSrc": "65467772df5823449b650a6c_Ron Benjamin.jpg"
  },
  {
    "name": "Agam Berger",
    "age": "19",
    "imageSrc": "65467772df5823449b650a74_Berger Agam.jpg"
  },
  {
    "name": "Gali Berman",
    "age": "26",
    "imageSrc": "65467772df5823449b650aed_Berman Gali.jpg"
  },
  {
    "name": "Ziv Berman",
    "age": "26",
    "imageSrc": "65467772df5823449b650aee_Berman Ziv.jpg"
  },
  {
    "name": "Ariel Bibas",
    "age": "4",
    "imageSrc": "65467772df5823449b650957_Ariel Bibas, 4.jpg"
  },
  {
    "name": "Kfir Bibas",
    "age": "10 MONTHS",
    "imageSrc": "65467772df5823449b650a21_Kfir Bibas, 9mo.jpg"
  },
  {
    "name": "Shiri Bibas",
    "age": "32",
    "imageSrc": "65467772df5823449b650a55_Shiri Bibas, 32.jpg"
  },
  {
    "name": "Yarden Bibas",
    "age": "34",
    "imageSrc": "65467772df5823449b6509a1_Yarden bibsd, 34.jpg"
  },
  {
    "name": "Elkana Bohbot",
    "age": "34",
    "imageSrc": "65467772df5823449b650ac5_Bohbot Elkana.jpg"
  },
  {
    "name": "Rom Braslavski",
    "age": "19",
    "imageSrc": "65467772df5823449b650730_Rom braslavski2.jpg"
  },
  {
    "name": "Yagev Buchshtab",
    "age": "34",
    "imageSrc": "65467772df5823449b650ad0_Buchshtab Yagev.jpg"
  },
  {
    "name": "Amit Esther Buskila",
    "age": "28",
    "imageSrc": "65467772df5823449b650a7a_Buskila Esther Amit.jpg"
  },
  {
    "name": "Tal Chaimi",
    "age": "42",
    "imageSrc": "65467772df5823449b650aa7_Chaimi Tal (1).jpg"
  },
  {
    "name": "Itai Chen",
    "age": "19",
    "imageSrc": "65467772df5823449b650b22_Chen Itai.jpg"
  },
  {
    "name": "Eliya Cohen",
    "age": "",
    "imageSrc": "65467772df5823449b650a66_אליה כהן.jpg"
  },
  {
    "name": "Nimrod Cohen",
    "age": "19",
    "imageSrc": "65467772df5823449b650a89_Cohen Nimrod.jpg"
  },
  {
    "name": "Amiram Cooper",
    "age": "84",
    "imageSrc": "65467772df5823449b650688_Amiram Cooper.jpg"
  },
  {
    "name": "Ariel Cunio",
    "age": "26",
    "imageSrc": "65467772df5823449b650a42_Konio Ariel, 26.jpg"
  },
  {
    "name": "David Cunio",
    "age": "33",
    "imageSrc": "65467772df5823449b650a8d_Konio David.jpg"
  },
  {
    "name": "Alexander Dancyg",
    "age": "75",
    "imageSrc": "65467772df5823449b650a87_Alexander Danzig.jpg"
  },
  {
    "name": "Ori Danino",
    "age": "24",
    "imageSrc": "65467772df5823449b650ab1_Danino Ori.jpg"
  },
  {
    "name": "Evyatar David",
    "age": "23",
    "imageSrc": "65467772df5823449b650ad5_David Evyatar.jpg"
  },
  {
    "name": "Sagui Dekel Chen",
    "age": "35",
    "imageSrc": "65467772df5823449b650a97_Sagui Dekel Chen, 35.jpg"
  },
  {
    "name": "Itzhk Elgarat",
    "age": "68",
    "imageSrc": "65467772df5823449b650a8a_Itzchak Algerat.jpg"
  },
  {
    "name": "Ronen Engel",
    "age": "54",
    "imageSrc": "65467772df5823449b650a9c_Angel Ronen, 55.jpg"
  },
  {
    "name": "Shani Gabay",
    "age": "26",
    "imageSrc": "65467772df5823449b650aa4_Gabay Shani.jpg"
  },
  {
    "name": "Carmel Gat",
    "age": "39",
    "imageSrc": "65467772df5823449b6509be_Carmel Gat.jpg"
  },
  {
    "name": "Itzhak Gelerenter",
    "age": "56",
    "imageSrc": "65467772df5823449b650b26_Gelerenter Itzhak.jpg"
  },
  {
    "name": "Daniel Gilboa",
    "age": "19",
    "imageSrc": "65467772df5823449b65068e_Daniel Gilboa.jpg"
  },
  {
    "name": "Guy Gilboa-Dalal",
    "age": "22",
    "imageSrc": "65467772df5823449b650ab4_Gilboa-Dalal Guy.jpg"
  },
  {
    "name": "Hadar Goldin",
    "age": "32",
    "imageSrc": "6548db871bce5d5e4d0f4f1a_hadar goldin.jpg",
    "notOct7": true,
  },
  {
    "name": "Romi Gonen",
    "age": "23",
    "imageSrc": "65467772df5823449b650745_Romi Gonen.jpg"
  },
  {
    "name": "Maya Goren",
    "age": "56",
    "imageSrc": "654a27a4a13de41036f935cd_Maya Goren.jpg"
  },
  {
    "name": "Ran Gvili",
    "age": "24",
    "imageSrc": "65467772df5823449b65070d_Ran gvili.jpg"
  },
  {
    "name": "Gad Haggai",
    "age": "73",
    "imageSrc": "65467772df5823449b650a8c_Gad Haggai.jpg"
  },
  {
    "name": "Inbar Haiman",
    "age": "27",
    "imageSrc": "65467772df5823449b650a70_Inbar Haiman.jpg"
  },
  {
    "name": "Ditza Heiman",
    "age": "81",
    "imageSrc": "65467772df5823449b650a0a_Heiman Diza.jpg"
  },
  {
    "name": "Maxim Herkin",
    "age": "35",
    "imageSrc": "65467772df5823449b650ac7_Harkin Maksym.jpg"
  },
  {
    "name": "Hersh Goldberg Polin",
    "age": "23",
    "imageSrc": "65467772df5823449b6507b2_Hersh Goldberg Polin.jpg"
  },
  {
    "name": "Eitan Horn",
    "age": "37",
    "imageSrc": "65467772df5823449b650a7f_Horn Eitan.jpg"
  },
  {
    "name": "Iair Horn",
    "age": "45",
    "imageSrc": "65467772df5823449b650a7d_Horn Iair.jpg"
  },
  {
    "name": "Tsachi Idan",
    "age": "51",
    "imageSrc": "65467772df5823449b650a5b_Tsachi Idan, 51.jpg"
  },
  {
    "name": "Guy Illouz",
    "age": "26",
    "imageSrc": "65467772df5823449b650abc_Illouz Guy.jpg"
  },
  {
    "name": "Ofer Kalderon",
    "age": "53",
    "imageSrc": "65467772df5823449b650a0c_Kalderon Ofer, 53.jpg"
  },
  {
    "name": "Segev Kalfon",
    "age": "25",
    "imageSrc": "65467772df5823449b65075c_Segev Kalfon.jpg"
  },
  {
    "name": "Dror Kaplun",
    "age": "68",
    "imageSrc": "65467772df5823449b650ac8_Kaplun Dror.jpg"
  },
  {
    "name": "Elad Katzir",
    "age": "47",
    "imageSrc": "6549030b9c3f74c1bb3887e2_Elad Katzir.jpg"
  },
  {
    "name": "Ofra Keidar",
    "age": "70",
    "imageSrc": "654a8a657d28ac01c1ec3478_ofra kidar.jpg"
  },
  {
    "name": "Denil Kimenfeld",
    "age": "65",
    "imageSrc": "65467772df5823449b650a76_Kimenfeld Denil.jpg"
  },
  {
    "name": "Rimon Kirsht",
    "age": "36",
    "imageSrc": "65467772df5823449b650ad1_Kirsht Rimon.jpg"
  },
  {
    "name": "Andrey Kozlov",
    "age": "27",
    "imageSrc": "65467772df5823449b650689_Andrey kozlov.jpg"
  },
  {
    "name": "Bar Kupershtein",
    "age": "22",
    "imageSrc": "65467772df5823449b650ab6_kupershtein Bar.jpg"
  },
  {
    "name": "Mia Leimberg",
    "age": "17",
    "imageSrc": "65467772df5823449b650a9f_Leimberg Mia.jpg"
  },
  {
    "name": "Gabriela Leinmberg",
    "age": "59",
    "imageSrc": "65467772df5823449b650a98_LEINMBERG GABRIELA.jpg"
  },
  {
    "name": "Eitan Levy",
    "age": "53",
    "imageSrc": "65467772df5823449b650a08_EITAN LEVI.jpg"
  },
  {
    "name": "Naama Levy",
    "age": "19",
    "imageSrc": "65467772df5823449b650ade_Levy Naama.jpg"
  },
  {
    "name": "Or Levy",
    "age": "33",
    "imageSrc": "65467772df5823449b650890_Or Levy.jpg"
  },
  {
    "name": "Elyakim Libman",
    "age": "24",
    "imageSrc": "65467772df5823449b650adf_Libman Elyakim.jpg"
  },
  {
    "name": "Oded Lifshitz",
    "age": "83",
    "imageSrc": "65467772df5823449b650acd_Lifshitz Oded,83.jpg"
  },
  {
    "name": "Alexander Lobanov",
    "age": "32",
    "imageSrc": "65467772df5823449b650ad6_Lobanov Alex.jpg"
  },
  {
    "name": "Shlomo Mansour",
    "age": "85",
    "imageSrc": "65467772df5823449b650b14_Mansour Shlomo.jpg"
  },
  {
    "name": "Clara Rosa Marman",
    "age": "63",
    "imageSrc": "65467772df5823449b65068f_Clara Marman.jpg"
  },
  {
    "name": "Almog Meir Jan  ",
    "age": "21",
    "imageSrc": "65467772df5823449b650687_Almog Meir Jan.jpg"
  },
  {
    "name": "Avera Mengistu",
    "age": "37",
    "imageSrc": "6548dc340af04ec3a9f3c2de_ABERA MANGISTO.jpg",
    "notOct7": true,
  },
  {
    "name": "Tamar Metzger",
    "age": "78",
    "imageSrc": "65467772df5823449b650a47_Metzger Tamar.jpg"
  },
  {
    "name": "Yoram Metzger",
    "age": "80",
    "imageSrc": "65467772df5823449b650a48_Metzger Yoram.jpg"
  },
  {
    "name": "Omri Miran",
    "age": "46",
    "imageSrc": "65467772df5823449b650a99_Miran Omri.jpg"
  },
  {
    "name": "Eitan Abraham Mor",
    "age": "23",
    "imageSrc": "654b69d2d691a6e949d5c908_Eitan Abraham Mor.jpg"
  },
  {
    "name": "Moshe Mozes Gadi  ",
    "age": "79",
    "imageSrc": "6547d14d28adc2157b3cc88a_Mozes Gadi Moshe.jpg"
  },
  {
    "name": "Abraham Munder",
    "age": "78",
    "imageSrc": "65467772df5823449b650686_Abraham minder.jpg"
  },
  {
    "name": "Omer Neutra",
    "age": "22",
    "imageSrc": "65467772df5823449b650b24_Neutra Omer.jpg"
  },
  {
    "name": "Tamir Nimrod",
    "age": "19",
    "imageSrc": "65467772df5823449b650a5a_Tamir Nimrodi, 19.jpg"
  },
  {
    "name": "Michel Nisenbaum",
    "age": "59",
    "imageSrc": "65467772df5823449b650b04_Nisenbaum Michel.jpg"
  },
  {
    "name": "Yosef Ohana",
    "age": "23",
    "imageSrc": "65467772df5823449b650ad2_Ohana Yosef.jpg"
  },
  {
    "name": "Alon Ohel",
    "age": "22",
    "imageSrc": "65467772df5823449b650acf_Ohel Alon.jpg"
  },
  {
    "name": "Alma Or",
    "age": "13",
    "imageSrc": "65467772df5823449b650ae4_Or Alma.jpg"
  },
  {
    "name": "Avinathan Or",
    "age": "30",
    "imageSrc": "65467772df5823449b650a6e_Or Avinathan.jpg"
  },
  {
    "name": "Dror Or",
    "age": "48",
    "imageSrc": "65467772df5823449b650ac8_Kaplun Dror.jpg"
  },
  {
    "name": "Chaim Peri",
    "age": "79",
    "imageSrc": "65467772df5823449b650b0b_Peri Chaim.jpg"
  },
  {
    "name": "Nadav Popplewell",
    "age": "51",
    "imageSrc": "65467772df5823449b650a81_Popplewell Nadav.jpg"
  },
  {
    "name": "Ofelia Roitman",
    "age": "77",
    "imageSrc": "65467772df5823449b650699_Ofelia roitman.jpg"
  },
  {
    "name": "Lior Rudaeff",
    "age": "61",
    "imageSrc": "65467772df5823449b650b0a_Template.jpg"
  },
  {
    "name": "Ada Sagi",
    "age": "75",
    "imageSrc": "65467772df5823449b650a96_Sagi Ada.jpg"
  },
  {
    "name": "Yonatan Mordechai Samerano",
    "age": "21",
    "imageSrc": "65467772df5823449b650ae9_Samerano yonathan Mordechai.jpg"
  },
  {
    "name": "Almog Sarusi",
    "age": "26",
    "imageSrc": "65467772df5823449b650b17_Sarusi Almog.jpg"
  },
  {
    "name": "Ron Scherman",
    "age": "19",
    "imageSrc": "65467772df5823449b650a52_Ron Scherman.jpg"
  },
  {
    "name": "Eli Sharabi",
    "age": "51",
    "imageSrc": "65467772df5823449b650ab9_Sharabi Eli.jpg"
  },
  {
    "name": "Yossi Sharabi",
    "age": "53",
    "imageSrc": "65467772df5823449b650aba_Template.jpg"
  },
  {
    "name": "Oron Shaul",
    "age": "30",
    "imageSrc": "65486c85d4f6279df27241e8_Shaul Oron.jpg",
    "notOct7": true,
  },
  {
    "name": "Omer Shem Tov  ",
    "age": "21",
    "imageSrc": "65467772df5823449b650ae7_Shem Tov Omer.jpg"
  },
  {
    "name": "Tal Shoham",
    "age": "38",
    "imageSrc": "653e4194f4c62f62e3250185_Shoham Tal.jpg"
  },
  {
    "name": "Haran Shoshan",
    "age": "66",
    "imageSrc": "65467772df5823449b650b1b_Shoshan Haran.jpg"
  },
  {
    "name": "Idan Shtivi",
    "age": "28",
    "imageSrc": "65467772df5823449b650aea_Shtivi Idan.jpg"
  },
  {
    "name": "Keith Samuel Siegel",
    "age": "64",
    "imageSrc": "65467772df5823449b650a78_Siegel Samuel Keith.jpg"
  },
  {
    "name": "Doron Steinbrecher",
    "age": "30",
    "imageSrc": "65467772df5823449b650aec_Steinbrecher Doron.jpg"
  },
  {
    "name": "Itai Svirsky",
    "age": "35",
    "imageSrc": "65467772df5823449b650ae6_Svirsky Itai.jpg"
  },
  {
    "name": "Merav Tal",
    "age": "53",
    "imageSrc": "65467772df5823449b650b23_Tal Merav.jpg"
  },
  {
    "name": "Elia Toledano",
    "age": "27",
    "imageSrc": "65467772df5823449b650b20_Elia toledano.jpg"
  },
  {
    "name": "Alexander (Sasha) Trupanob",
    "age": "28",
    "imageSrc": "65467772df5823449b650a9b_Trupanob Alexander.jpg"
  },
  {
    "name": "Ofir Tzarfati",
    "age": "27",
    "imageSrc": "65467772df5823449b650695_Ofir tzarfati.jpg"
  },
  {
    "name": "Judy Weinstein",
    "age": "70",
    "imageSrc": "65467772df5823449b650a8b_Template.jpg"
  },
  {
    "name": "Ilan Weiss",
    "age": "56",
    "imageSrc": "65467772df5823449b650b05_Weiss Ilan.jpg"
  },
  {
    "name": "Omer Wenkert",
    "age": "22",
    "imageSrc": "65467772df5823449b650add_wenkert Omer.jpg"
  },
  {
    "name": "Chanan Yablonka",
    "age": "42",
    "imageSrc": "65467772df5823449b650b07_Yablonka Chanan.jpg"
  },
  {
    "name": "Ohad Yahalomi",
    "age": "49",
    "imageSrc": "65467772df5823449b650a5e_Yahalomi Ohad, 49.jpg"
  },
  {
    "name": "Yaakov Yair",
    "age": "59",
    "imageSrc": "65467772df5823449b650819_Yair Yaakov.jpg"
  },
  {
    "name": "Arbel Yehoud",
    "age": "28",
    "imageSrc": "65467772df5823449b650a60_Yaood Arbel, 28.jpg"
  },
  {
    "name": "Dolev Yehoud",
    "age": "35",
    "imageSrc": "65467772df5823449b650a5f_Yahod Dolev, 35.jpg"
  },
  {
    "name": "Eden Yerushalmi",
    "age": "24",
    "imageSrc": "65467772df5823449b650ae8_Yerushalmi Eden.jpg"
  },
  {
    "name": "Arie Zalmanowicz",
    "age": "85",
    "imageSrc": "65467772df5823449b650a62_Zalmanovich Arye.jpg"
  },
  {
    "name": "Matan Zangauker",
    "age": "24",
    "imageSrc": "65467772df5823449b650ae0_Zangauker Matan.jpg"
  },
  {
    "name": "Eden Zecharya",
    "age": "28",
    "imageSrc": "65467772df5823449b650ac4_Zecharya Eden.jpg"
  },
  {
    "name": "Shlomi Ziv",
    "age": "40",
    "imageSrc": "65467772df5823449b65087b_Shlomi ziv.jpg"
  }
].map(d => ({ ...d, name: d.name.trim() }))