import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import './App.css';
import Home from './components/Home';
import { MainContext } from './contexts/MainContext';
import ImageOverlay from './components/ImageOverlay';
import { Box, Button, Container, CssBaseline, Divider, ThemeProvider, Typography, createTheme } from '@mui/material';
import CropImage from './components/CropImage';
import { IHostageData, hostages } from './hostages';
import { Crop } from 'react-image-crop';
import TranslateIcon from '@mui/icons-material/Translate';
import { Route, Routes, Navigate, useSearchParams } from 'react-router-dom';
import { useLocalization, Localization } from './Localization';


const routes: { path: string, element: ReactElement }[] = [
  { path: '/', element: <Home /> },
  { path: '/crop', element: <CropImage /> },
  { path: '/result', element: <ImageOverlay /> },
  { path: '/*', element: <Navigate to='/' /> },
]

export interface IMainContext {
  mergedImageSrc: string, setMergedImageSrc: Dispatch<SetStateAction<string>>,
  randomizeHostage: () => void,
  setHostage: (name: string) => void,
  restart: () => void,
  userImageSrc: string, setUserImageSrc: Dispatch<SetStateAction<string>>,
  crop: Crop | undefined, setCrop: Dispatch<SetStateAction<Crop | undefined>>,
  userImage: HTMLImageElement | undefined, setUserImage: Dispatch<SetStateAction<HTMLImageElement | undefined>>,
  hostageData: IHostageData,
  localization: Localization,
  changeFrame: () => void,
  frame: number,
  hebPhraseImage: HTMLImageElement,
}



export const DOMAIN = "BringThemFrame.com"

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#E99C02' },
  },
  typography: {
    "fontFamily": `'Rubik', sans-serif`,
  }
});

const TOTAL_FRAMES = 2;

const getRandomHostage = (): IHostageData => hostages[Math.floor(Math.random() * hostages.length)];

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userImageSrc, setUserImageSrc] = useState<string>('');
  const [userImage, setUserImage] = useState<HTMLImageElement>();
  const [mergedImageSrc, setMergedImageSrc] = useState<string>('');
  const [hostageData, setHostageData] = useState<IHostageData>(getRandomHostage());
  const [crop, setCrop] = useState<Crop>();
  const [frame, setFrame] = useState<number>(1);
  let localization = useLocalization(hostageData, searchParams.get("lang"));

  const hebPhraseImage = new Image()
  hebPhraseImage.src = '/HEB_BOTTOM.png'

  useEffect(() => {
    if (searchParams.get("lang") !== localization.lang)
      setSearchParams({ lang: localization.lang })
  }, [localization.lang, searchParams, setSearchParams])

  const changeFrame = () => {
    setFrame(p => (p + 1) % TOTAL_FRAMES)
  }


  const randomizeHostage = () => {
    setHostageData(getRandomHostage());
  }

  const setHostage = (name: string) => {
    const hostage = hostages.find(h => h.name === name);
    if (hostage) setHostageData(hostage);
  }

  const restart = () => {
    setUserImageSrc('');
    setMergedImageSrc('');
    setHostageData(getRandomHostage());
  }

  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <MainContext.Provider value={{
          hostageData,
          mergedImageSrc, setMergedImageSrc,
          randomizeHostage, restart, setHostage, userImageSrc, setUserImageSrc,
          crop, setCrop, userImage, setUserImage,
          localization, changeFrame, frame, hebPhraseImage
        }} >
          <Container component="main" maxWidth='xs'>
            <CssBaseline />
            <Routes>
              {routes.map(route =>
                <Route key={route.path} path={route.path} element={
                  <Box
                    sx={{
                      marginTop: '0.6rem',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography component="h1" variant="h4" fontWeight={800} >
                      BRING THEM
                    </Typography>
                    <Typography component="h1" variant="h4" fontWeight={800} >
                      HOME NOW
                    </Typography>
                    <Typography component="h1" variant="h6" fontWeight={300}>
                      {localization.text['subtitle']}
                    </Typography>
                    <br />
                    {route.element}
                    <br />
                    <Divider style={{ width: '100%' }} />
                    <br />
                    <Typography sx={{ direction: localization.dir }} variant='body1' align={localization.align}>
                      {localization.text['description1']}
                    </Typography>
                    <br />
                    <Typography sx={{ direction: localization.dir }} variant='body1' align={localization.align}>
                      {localization.text['description2']}
                    </Typography>
                    <br />
                    <Typography sx={{ direction: localization.dir }} variant='body1' align={localization.align} >
                      {localization.text['description3']}
                    </Typography>
                    <br />
                    <Button dir='ltr' variant='text' disableRipple sx={{ position: 'absolute', top: '8px', left: '8px', p: 0, m: 0, minWidth: 0 }} size='small' onClick={() => localization.setLang(localization.lang === 'en' ? 'he' : 'en')} >
                      <TranslateIcon sx={{ fontSize: 'medium' }} />
                      <Typography fontSize='0.8rem' variant='body2'>
                        {localization.text['languageInitials']}
                      </Typography>
                    </Button>
                  </Box>

                }></Route>
              )}
            </Routes>
          </Container>
        </MainContext.Provider>
      </ThemeProvider >
    </div >
  );
}

export default App;
