import { useContext, useEffect, useState } from "react";
import { MainContext } from "./contexts/MainContext";
import { IHostageData } from "./hostages";

export type Lang = 'en' | 'he' | 'sp' | 'ru'

const langSettings: Record<Lang, { align: 'left' | 'right', dir: 'rtl' | 'ltr' }> = {
   'en': {
      align: 'left',
      dir: 'ltr',
   },
   'he': {
      align: 'right',
      dir: 'rtl',
   },
   'ru': {
      align: 'left',
      dir: 'ltr',
   },
   'sp': {
      align: 'left',
      dir: 'ltr',
   },
}

type LocalizationKey =
   "title" |
   "subtitle" |
   "description1" |
   "description2" |
   "description3" |
   "languageInitials" |
   "browserNotSupported" |
   "browserDisclaimer" |
   "buttonStart" |
   "buttonCrop" |
   "postContent1" |
   "postContent2" |
   "postContent3" |
   "postContent4" |
   "postContentChildrensDay" |
   "buttonRestart" |
   "buttonRandom" |
   "buttonChangeFrame" |
   "buttomDownload" |
   "suggestedContent" |
   "contentCopied"

type LocalizationConfig = Record<LocalizationKey, Record<Lang, string | undefined>>

export type Localization = {
   setLang: React.Dispatch<React.SetStateAction<Lang>>,
   lang: Lang,
   dir: 'rtl' | 'ltr',
   align: 'right' | 'left',
   text: Partial<Record<LocalizationKey, string | undefined>>
};

export function useLocalization(hostageData: IHostageData, defaultLang: string | null): Localization {
   const [lang, setLang] = useState<Lang>(langSettings[defaultLang as Lang] ? defaultLang as Lang : 'he');
   const [text, setText] = useState<Localization['text']>({})

   useEffect(() => {
      if (hostageData) {
         const { name: hostageName, age: hostageAge } = hostageData;

         const Oct7 = new Date(2023, 9, 7);
         const thisDate = new Date();
         const daysPassed = Math.round((thisDate.getTime() - Oct7.getTime()) / (1000 * 60 * 60 * 24));

         const localizationConfig: LocalizationConfig = {
            "title": {
               "en": `BRING THEM HOME`,
               "he": ``,
               "ru": ``,
               "sp": ``
            },
            "subtitle": {
               "en": `FRAME CREATOR`,
               "he": `מסגרות למען שחרור החטופים`,
               "ru": `Рамки для освобождения похищенных`,
               "sp": `Creador de marcos`
            },
            "description1": {
               "en": `On October 7th, 2023, more than 1200 Israelis were killed in Hamas' terror attack. Over 230 hostages were kidnapped to Gaza, including men, women, children and elderly.`,
               "he": `בתאריך 7.10.2023, למעלה מ-1200 ישראלים נרצחו במתקפת הטרור של ארגון חמאס. יותר מ-230 נחטפו לעזה, וביניהם גברים, נשים, ילדים וקשישים.`,
               "ru": `7 октября 2023 года, более 1200 израильтян были убиты в результате теракта, совершенного организацией ХАМАС. Более 230 человек были похищены в Газу, включая мужчин, женщин, детей и стариков.`,
               "sp": `El 7 de octubre de 2023, más de 1200 israelíes fueron asesinados en el ataque terrorista de la organización Hamás.  Más de 230 personas fueron secuestradas en Gaza, hombres, mujeres, niños y ancianos.`
            },
            "description2": {
               "en": `We invite you to add a frame of one of the hostages to your photo and share it online. You can either include a random hostage or choose a specific name from the list.`,
               "he": `אתר זה מאפשר להוסיף מסגרת עם אחד החטופים לתמונות שלכם, כך שתוכלו לשתף אותן כתמונות פרופיל ולהעלות את המודעות לנושא החטופים. בכך אנחנו מקווים לתת דגש על כל חטוף וחטופה באופן אישי, תוך שמירה על אחידות ויזואלית.`,
               "ru": ``,
               "sp": `Te invitamos a agregar un marco de uno de los rehenes a tu foto y compartirla en línea.  Puede incluir un rehén aleatorio o elegir un nombre específico de la lista.`
            },
            "description3": {
               "en": `This website is a private initiative. The hostages list is updated weekly based on bringthemhomenow.net. Your uploaded images are not stored on our servers.\n`,
               "he": `אתר זה הוא יוזמה פרטית. רשימת החטופים ותמונותיהם מתעדכנים מעת לעת על פי אתר  bringthemhomenow.net. כדי לשמור על פרטיותכם, התמונות שאתם מעלים לא נשמרות על השרתים, והן נגישות עבורכם בלבד.`,
               "ru": ``,
               "sp": `Este sitio es una iniciativa privada.  La lista de secuestrados y sus fotografías se actualiza periódicamente según el sitio web Bringthemhomenow.net.  Para mantener su privacidad, las fotos que cargue no se guardan en los servidores y solo usted podra acceder a ellas.`
            },
            "languageInitials": {
               "en": `EN`,
               "he": `עב`,
               "ru": `RU`,
               "sp": `ES`
            },
            "browserNotSupported": {
               "en": `This website is not supported in this browser. Please use external browser.`,
               "he": `האתר לא נתמך בדפדפן הפנימי של פייסבוק ואינסטגרם. אנא פתחו אותו מחדש בדפדפן החיצוני, לדוגמא:`,
               "ru": `Работа сайта в этом браузере не поддерживается`,
               "sp": `El sitio web no es compatible con el navegador interno de Facebook e Instagram.  Vuelva a abrirlo en el navegador externo, por ejemplo:`
            },
            "browserDisclaimer": {
               "en": `* May look different depending on your device.`,
               "he": `* התפריט עשוי להיראות שונה כתלות במכשיר ובשפה שלך.`,
               "ru": ``,
               "sp": `El menú puede verse diferente según su dispositivo e idioma.`
            },
            "buttonStart": {
               "en": `Start`,
               "he": `העלה תמונה`,
               "ru": ``,
               "sp": `Comenzar`
            },
            "buttonCrop": {
               "en": `Crop`,
               "he": `חתוך תמונה`,
               "ru": ``,
               "sp": `Recortar la imagen`
            },
            "postContent1": {
               "en": `${hostageName} was kidnapped by Hamas terrorists, together with over 230 men, women and children. Add a frame to your photo and join our call to #BringThemHomeNow`,
               "he": `מסגרת למען השבת החטופים:`,
               "ru": ``,
               "sp": `${hostageName} fue secuestrado por terroristas de Hamás, junto con más de 230 hombres, mujeres y niños.  Añade un marco a tu foto y únete a nuestro llamado #TráelosACasaAhora`
            },
            "postContent2": {
               "en": `No one will take you down from our walls. 🤍`,
               "he": `את כולם ✊🏼`,
               "ru": ``,
               "sp": `Nadie te bajará de nuestros muros.  🤍`
            },
            "postContent3": {
               "en": `Day ${daysPassed} since ${hostageName} was kidnapped to Gaza. Stand with us and create your frame at:`,
               "he": `${daysPassed} ימים בשבי החמאס. מחזירים אותם הביתה עכשיו.`,
               "ru": ``,
               "sp": `${daysPassed} Días desde que ${hostageName} fue secuestrado en Gaza.  Únete y cree su marco en:`
            },
            "postContent4": {
               "en": `${hostageName}, ${hostageAge}, KIDNAPPED BY HAMAS.`,
               "he": ``,
               "ru": ``,
               "sp": `${hostageName}, ${hostageAge}, SECUESTRADO POR HAMAS.`
            },
            "postContentChildrensDay": {
               "en": `Today is World Children's Day, and ${hostageName}, ${hostageAge}, is still being held by Hamas. #ForEveryChild #WorldChildrensDay #BringThemHomeNow`,
               "he": `יום הילד הבינלאומי, והילדים שלנו עדיין מוחזקים בעזה. #ForEveryChild #WorldChildrensDay #BringThemHomeNow`,
               "ru": ``,
               "sp": `Hoy es el Día Mundial del Niño y ${hostageName}, ${hostageAge}, todavía está retenido por Hamás.  #ParaCadaNiño #DíaMundialDelNiño #TráelosACasaAhora`
            },
            "buttonRestart": {
               "en": `Restart`,
               "he": `התחל מחדש`,
               "ru": ``,
               "sp": `Reiniciar`
            },
            "buttonRandom": {
               "en": `Random`,
               "he": `בחר באקראי`,
               "ru": ``,
               "sp": `Aleatorio`
            },
            "buttonChangeFrame": {
               "en": `Change Frame`,
               "he": `שנה מסגרת`,
               "ru": ``,
               "sp": ``
            },
            "buttomDownload": {
               "en": `Download`,
               "he": `הורדה`,
               "ru": ``,
               "sp": `Descargar`
            },
            "suggestedContent": {
               "en": `Suggested post content:`,
               "he": `הצעות תוכן לפוסט (לחצו כדי להעתיק):`,
               "ru": ``,
               "sp": `Sugerencias de contenido para publicar (haga clic para copiar):`
            },
            "contentCopied": {
               "en": `Text copied to clipboard`,
               "he": `תוכן הפוסט הועתק בהצלחה`,
               "ru": ``,
               "sp": `El contenido se ha copiado correctamente.`
            }
         }
         const text: Record<string, string | undefined> = {};
         for (const key in localizationConfig) text[key] = localizationConfig[key as LocalizationKey][lang];
         setText(text);
      }
   }, [hostageData, lang])

   return { lang, setLang, text, dir: langSettings[lang].dir, align: langSettings[lang].align };
}
